<template>
  <div style="border-radius: 12px;">
    <v-card class="param" >
      <v-card-title class="py-1">
        <h4 class="title-formDriverVehicleModal">
          <v-icon size="16" class="ml-4 mr-2">{{ iconLetter }}</v-icon>
          <span class="titlespan">{{ titleLetter }}</span>
        </h4>
        <v-spacer />
        <v-btn depressed text color="grey" @click.stop="$emit('closeForm')">
          <v-icon small class="mr-1">fa-regular fa-rectangle-xmark</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pl-8">
        <v-form v-model="valida" dense>
          <v-container fluid>
            <v-row class="mt-1">
              <v-col cols="6" class="my-0 pt-0">
                <div class="subtitle pb-1">
                  <h4 class="secondary--text">{{ $t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.vehicle") }}</h4>
                </div>
              </v-col>
              <v-col cols="6" class="my-0 pt-0">
                <div class="subtitle pb-1">
                  <h4 class="secondary--text">{{ $t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.driver") }}</h4>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-2 pb-0">
                <SelectAutocomplete
                  v-model="vehicle"
                  url="/Vehicles"
                  return-object
                  item-text="licensePlate"
                  maxlength="10"
                  dense
                  item-value="licensePlate"
                  prepend-icon="fa-solid fa-truck-moving"
                  :selected="edit ? itemEdit.vehicle : ''"
                  :label="$t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.license_plate')"
                  :placeholder="$t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.license_plate')"
                  :rules="[required($t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.license_plate'), 1)]"
                />
              </v-col>
              <v-col cols="6" class="pt-2 pb-0">
                <SelectAutocomplete
                  ref="driver"
                  v-model="driver"
                  url="/Drivers"
                  dense
                  return-object
                  item-text="identification"
                  item-value="identification"
                  prepend-icon="fa-solid fa-id-card"
                  :label="$t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.number_id')"
                  :placeholder="$t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.number_id')"
                  :search-input.sync="driverCreate"
                  maxlength="15"
                  :selected="edit ? itemEdit.driver.identification_driver : ''"
                  :rules="[required($t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.number_id'), 1)]"
                >
                  <template slot="no-data">
                    <v-list-item @click="loadModalNewDriver()">
                      <v-list-item-title>{{ $t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.create_new_driver") }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </SelectAutocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" class="my-0 pt-0">
                <div class="subtitle pb-1">
                  <h4 class="secondary--text">{{ $t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.business_transport") }}</h4>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-2 pb-0">
                <SelectAutocomplete
                  v-model="transporter"
                  url="/TransportCompany"
                  item-text="businessName"
                  item-value="businessName"
                  dense
                  return-object
                  :selected="edit ? itemEdit.transporter.company_transporter : ''"
                  prepend-icon="fa-solid fa-building"
                  required
                  :label="$t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.business_transport')"
                  :placeholder="$t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.business_transport')"
                  :rules="[required($t('components.views.settings.driver_vehicle.modal_form_driver_vehicle.business_transport'), 1)]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="border-top my-0">
        <v-spacer />
        <v-btn
          color="secondary"
          :disabled="!valida"
          depressed
          class="white--text"
          @click="saveVehicleDriver()"
        >
          <v-icon small class="fa-solid fa-bookmark mr-1" />
          {{ $t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.save") }}
        </v-btn>
        <v-btn depressed @click.stop="$emit('closeForm')">
          <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
          {{ $t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.exit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogDriver" scrollable width="35%" persistent>
      <ModalCreateDriver
        @closeForm="onCloseFormDriver()"
        @driverCreated="onDriverCreated"
        :idDriver="driverCapture"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validate from "@/plugins/validate";
export default {
  components: {
    ModalCreateDriver: () =>
      import(
        /* webpackChunkName: "ModalCreateDriver" */ "@/components/views/Settings/DriverVehicle/Modals/ModalCreateDriver/ModalCreateDriver.vue"
      ),
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ...validate,
      vehicle: {},
      driverCreate: null,
      driverCapture: "",
      dialogDriver: false,
      valida: false,
      driver: {},
      itemEdit: {
        driver: {
          idDriver: "",
          identification_driver: "",
          last_name_driver: "",
          name_driver: "",
          phone_driver: "",
        },
        id: "",
        idCompany: "",
        transporter: {
          company_transporter: "",
          idTransporter: "",
          identification_company_transporter: "",
          identification_type: "",
          type_company: "",
        },
        vehicle: "",
      },
      transporter: {},
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    titleLetter() {
      return !this.edit ? this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.new_record") : this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.edit");
    },
    iconLetter() {
      return !this.edit ? "fa-solid fa-plus" : "fa-solid fa-edit";
    },
  },
  watch: {},
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    async saveVehicleDriver() {
      if (!this.edit) {
        let userMap = JSON.parse(this.user);

        //Validación de Campos vacíos
        const idCompany = userMap.user.idCompany;
        const idTransporter =
          this.transporter.idCompany !== undefined ? this.transporter.idCompany : "";
        const idDriver = this.driver.idDriver !== undefined ? this.driver.idDriver : "";
        const vehicle = this.vehicle.licensePlate !== undefined ? this.vehicle.licensePlate : "";

        await this.axios
          .post(`/VehicleDriver/Create`, {
            idCompany,
            idTransporter,
            idDriver,
            vehicle,
          })
          .then(() => {
            this.$emit("reloadTable");
            this.showSnack({
              text: this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.driver_vehicle_save_success"),
              title: this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.driver_vehicle_save_error"),
              name: "error",
            });
          });
      } else {
        let editForm = { ...this.itemEdit };

        editForm.driver.identification_driver =
          this.driver.idDriver !== undefined
            ? this.driver.identification
            : editForm.driver.identification_driver;

        editForm.vehicle =
          this.vehicle.licensePlate !== undefined ? this.vehicle.licensePlate : editForm.vehicle;

        editForm.transporter.company_transporter =
          this.transporter.idCompany !== undefined
            ? this.transporter.businessName
            : editForm.transporter.company_transporter;

         editForm.transporter.idTransporter =
          this.transporter.idCompany !== undefined
            ? this.transporter.idCompany
            : editForm.transporter.idTransporter;

        await this.axios
          .put(`/VehicleDriver/Update`, editForm)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.driver_vehicle_update_success"),
              title: this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t("components.views.settings.driver_vehicle.modal_form_driver_vehicle.driver_vehicle_update_error"),
              name: "error",
            });
          });
      }
      this.$emit("closeForm");
      this.$emit("reloadTable");
    },

    async onDriverCreated(data) {
      if (data) {
        await this.$refs.driver.loadData();
      }
    },

    loadModalNewDriver() {
      let driver = this.driverCreate;
      this.driverCapture = driver;
      this.onOpenFormDriver();
    },
    onOpenFormDriver() {
      this.dialogDriver = true;
    },
    onCloseFormDriver() {
      this.dialogDriver = false;
    },
  },
  mounted() {
    this.itemEdit = Object.assign({}, this.data);
  },
};
</script>

<style scoped>
@import './ModalFormDriverVehicleStyle.scss';
</style>
